import $ from 'jquery';
import './util/existence.jquery';
import { wrap } from './util';

let animating = false;

function setActiveSection($sectionLink) {
    if (!$sectionLink) return;
    $('.section-link.active').removeClass('active');
    if ($sectionLink.exists()) {
        $sectionLink.addClass('active');
    }
}

function findSection(id, sections) {
    return sections.find((sec) => sec.attr('id') === id);
}

function scrollToSection($section) {
    if (animating) return;
    animating = true;

    let animationCallbackHasRun = false;
    $('body, html').animate(
        {
            scrollTop: $section.offset().top - 45,
        },
        1000,
        () => {
            if (animationCallbackHasRun) {
                return;
            }
            animationCallbackHasRun = true;
            animating = false;
            $('.js-child-nav').removeClass('expanded');
            $('.js-local-nav').removeClass('expanded');

            const scrollPosBeforeHashChange = $('html,body').scrollTop();
            window.location.hash = $section.attr('id');
            $('html,body').scrollTop(scrollPosBeforeHashChange);
        },
    );
}

function checkForInitialSection($sectionLinks, navbarThreshold) {
    if (window.location.hash.length <= 1) return;
    const decodedHash = decodeURI(window.location.hash);
    const section = $(decodedHash);
    const sectionLink = $sectionLinks.find((e) => e.data('id') === decodedHash.slice(1));

    if (section.exists()) {
        scrollToSection(section);
        setActiveSection(sectionLink);
        toggleFixed($('.js-local-nav'), navbarThreshold);
    }
}

function toggleFixed(navbar, threshold) {
    const windowY = $(window).scrollTop();
    if (!navbar) return;
    if (windowY >= threshold && !navbar.hasClass('fixed')) navbar.addClass('fixed');
    if (windowY <= threshold && navbar.hasClass('fixed')) {
        $('.active').removeClass('active');
        navbar.removeClass('fixed');
    }
}

function findActiveSection(sections) {
    const windowY = $(window).scrollTop();
    return sections
        .slice()
        .reverse()
        .find((sec) => sec.offset().top - 78 < windowY); // 78px is the height of the local navigation bar
}

function setupToggle(navbar) {
    [...document.querySelectorAll('.jump-to')].forEach((el) => {
        el.addEventListener('click', (ev) => {
            ev.preventDefault();
            navbar.toggleClass('expanded');

            return false;
        });
    });
}

function setupChildNav() {
    const pageElementsWithBg = document.querySelectorAll('.rows [class*="block-"], .rows [class*="bg-"]');
    const wrapper = document.querySelector('.page-bg-wrapper');

    if (wrapper && pageElementsWithBg && pageElementsWithBg.length > 0) {
        const pageTopElementBGClass = [...pageElementsWithBg[0].classList].find(
            (e) => e.startsWith('block-') || e.startsWith('bg-'),
        );

        if (pageTopElementBGClass) {
            wrapper.classList.add(pageTopElementBGClass);
        }
    }
}

function init() {
    if ($('.js-child-nav').exists()) {
        setupChildNav();
        return setupToggle($('.js-child-nav'));
    }
    if ($('.js-local-nav').none()) return;

    const navbar = $('.js-local-nav');
    const headerHeight = $('header').innerHeight();
    const navbarThreshold = navbar.offset().top - headerHeight;
    const sections = wrap($('.section'));
    const links = wrap($('.section-link'));
    setTimeout(() => checkForInitialSection(links, navbarThreshold), 500);

    document.addEventListener(
        'scroll',
        _.throttle(() => {
            if (!animating) {
                toggleFixed(navbar, navbarThreshold);
            }
            if (!navbar) return;
            if (navbar.hasClass('fixed')) {
                const section = findActiveSection(sections);
                if (!section) return;
                if (section) {
                    let link = links.find((el) => el.data('id') === section.attr('id'));
                    if (!link) return;
                    setActiveSection(link);
                }
            }
        }, 50),
    );

    setupToggle(navbar);

    [...document.querySelectorAll('.section-link')].forEach((el) => {
        el.addEventListener('click', (ev) => {
            ev.preventDefault();
            const $el = $(ev.currentTarget);
            if (!$el) return;
            if (!$el.hasClass('practical-info-link')) {
                setActiveSection($el);
                const activeSection = findSection($el.data('id'), sections);
                scrollToSection(activeSection);
            }
        });
    });

    [...document.querySelectorAll('.practical-info-link')].forEach((el) => {
        el.addEventListener('click', (ev) => {
            ev.preventDefault();
            scrollToSection($('.practical-info'));
        });
    });
    
    const home = document.getElementById('home');
    if (home) {
        home.addEventListener('click', () => {
            scrollToSection(sections[0]);
        });
    }
}

$(() => {
    init();
});
